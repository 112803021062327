import React, {useEffect, useState} from 'react';
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'

import api from '../../services/api';

import btnApple from '../../assets/images/apple.png';
import btnAndroid from '../../assets/images/google-play.png';
import { isValidPhoneNumber } from 'react-phone-number-input';

export default function Home(){

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [affiliateGuid, setAffiliateGuid] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [modalSuccessVisible, setModalSuccessVisible] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if(params.id && params.id !== ''){
      //console.log(params.id);
      setAffiliateGuid(params.id);
    }
  }, []);

  async function handleSubmit(e){
    e.preventDefault();
    setErrorMessages([]);
    let errors = [];

    if(phoneNumber.length < 5 || !isValidPhoneNumber(phoneNumber)){
      errors.push('Insira um telefone válido');
      setErrorMessages(errors);
      return false;
    }

    //alert('Nome: '+userName +'\n'+'Email: '+userEmail+'\n'+'Senha: '+userPassword+'\n'+'Guid: '+affiliateGuid);
    if(errors.length === 0){
      try{
        const response = await api.post('/Account/affiliate-program', {
          name: userName,
          email: userEmail,
          phoneNumber: phoneNumber,
          password: userPassword,
          affiliateGuid: affiliateGuid ? affiliateGuid : '52eac462-ab12-49be-b42b-68fe66afbf58',
        });
  
        //console.log(response);
        if(response.status === 200){
          setModalSuccessVisible(true);
          setUserName('');
          setUserEmail('');
          setPhoneNumber('');
          setUserPassword('');
        }
      }catch(e){
        //console.log(e.response);
        if(e.response.data.errorMessages.length > 0){
          setErrorMessages(e.response.data.errorMessages);
        }
      }
    }else{
      setErrorMessages(errors)
    }
    
    return false;
  }

  return(
    <>
      <div className="w-embed">

      </div>
      <div className="hero-dividendos-me-section dividendosme">
        <div className="hero-plataformas-container dividendosme">
          <div className="content-hero-text-dividendos dividendosme">
            <div className="logos-plataformas-div dividendosme"><img loading="lazy"
              src="./Dividendos ME_files/60abd5167f177374df0f4778_dividendos-me-logo.png" alt="logo"
              className="logo-plataformas" /></div>
            <div className="hero-mobile-dividendosme"><img src="./Dividendos ME_files/60e31bface945b03c14ebdf5_topo-mobile.png"
              loading="lazy" alt="" className="image-333" /></div>
            <h1 className="font-size-1 text-white text-mobile-tablet-ajust-plataformas dividendos-me mt-2">Controle seus
              investimentos de um jeito <strong className="bold-text-34">simples e grátis</strong>.</h1>
            <div className="font-size-5 text-white mobo-align-center aj-mobo-f-t-light-p mt2">Utilize recursos que fazem a
              diferença na hora de administrar seus investimentos</div><a
                href="#form-cadastro"
                className="btn-primary mt-4 mobo-auto btn-green w-button">Cadastre-se grátis</a>
          </div>
        </div>
      </div>
      <div className="intro-dividendos-me">
        <div className="dividendosme-intro">
          <div className="dividendos-left">
            <div className="font-size-2">Você recebeu um convite, não perca a oportunidade</div>
            <div className="font-size-7 mt-4">Para aproveitar, basta seguir estes passos:<br /><strong
              className="text-bold"><br />1</strong>Crie seu usuário e senha clicando no botão abaixo e preenchendo o
              formulário<br /><br /><strong className="text-bold">2</strong> Baixe o App Dividendos.me na loja de aplicativos do seu
              celular<br /><br /><strong className="text-bold">3</strong> Acesse o app e faça login com seu usuário e senha. Pronto!
              Sua forma de organizar seus investimentos nunca mais será a mesma.</div><a
                href="#form-cadastro"
                className="btn-primary mt-4 w-button">Cadastre-se grátis</a>
          </div>
          <div className="dividendosme-center"><img src="./Dividendos ME_files/608ff1ce4b9b3bed1bb36d28_icon-smartphone.png"
            loading="lazy" alt="icone smartphone" className="mb-3" />
            <div className="font-size-5">Disponível na App Store e Google Play.</div>
          </div>
          <div className="dividendos-me-right"><img src="./Dividendos ME_files/608b63d8b07f9dde88c24dac_icon-graph-desk.png"
            loading="lazy" alt="ícone computador" className="mb-3" />
            <div className="font-size-5">Disponível para Web.<br />&nbsp;</div>
            <div className="font-size-7 mt-4 mobile-hide"></div>
          </div>
        </div>
      </div>
      <div className="plataformas-beneficios-div dividendosme">
        <div className="img-dividendosme dividendosme">
          <div className="div-dividendos-beneficos-img"><img loading="lazy"
            sizes="(max-width: 991px) 100vw, (max-width: 1439px) 40vw, (max-width: 1919px) 33vw, 24vw"
            srcSet="https://assets.website-files.com/5dc1d03d539fbeea86005a14/60e31c3a9960ff545c21e7ec_meio-pagina-img-p-500.png 500w, https://assets.website-files.com/5dc1d03d539fbeea86005a14/60e31c3a9960ff545c21e7ec_meio-pagina-img.png 758w"
            src="./Dividendos ME_files/60e31c3a9960ff545c21e7ec_meio-pagina-img.png" alt=""
            className="img-beneficios-dividendosme-desk" /></div>
        </div>
        <div className="plataformas-beneficios-texts dividendosme">
          <h2 className="font-size-2">Aplicativo <strong className="text-bold">Dividendos.me</strong></h2>
          <div className="div-block-970"><img src="./Dividendos ME_files/60e31bfab973b626326e83b5_meio-pagina-img.png"
            loading="lazy" alt="" className="img-tablet-toro-light mt-5" /></div>
          <div className="mt-4">
            <div className="font-size-4 text-dark-light">Uma plataforma construída por investidores, para investidores: </div>
          </div>
          <div className="mt-4">
            <div className="font-size-4 text-dark-light text-medium">Gráficos diferenciados</div>
            <div className="font-size-6 mt-1">Acompanhe seus investimentos diariamente com gráficos elaborados de forma objetiva
              e fácil de ler. </div>
          </div>
          <div className="mt-4">
            <div className="font-size-4 text-dark-light text-medium">Importação automática</div>
            <div className="font-size-6 mt-1">Informando suas credenciais do CEI, você não perde tempo passando todas suas
              informações manualmente. Seus investimentos são sincronizados automaticamente. </div>
          </div>
          <div className="mt-4 mb-5">
            <div className="font-size-4 text-dark-light text-medium">Contas e Subcarteiras </div>
            <div className="font-size-6 mt-1">Importe quantas contas quiser e crie quantas subcarteiras desejar. Assim, você
              organiza seus investimentos como preferir e acompanha tudo do seu jeito.</div>
          </div>
        </div>
      </div>
      <section id="plano-ouro" className="plano-ouro-dividencods-me dividendosme">
        <div className="plano-ouro-content-div dividendosme">
          <div className="plano-ouro-img dividendosme"></div>
          <div className="desktop-cards-plano-ouro-dividendos dividendosme">
            <div className="dividendos-me-cards w-row">
              <div className="column-372 dividendos-me w-col w-col-6">
                <div className="card-plano-ouro dividendosme">
                  <div className="item-card-plano-ouro-desktop-investap"><img
                    src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
                    alt="icone checkup" className="image-327" />
                    <div className="font-size-6 ml-2 lh-130">Suporte técnico (Canal exclusivo)</div>
                  </div>
                  <div className="item-card-plano-ouro-desktop-investap"><img
                    src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
                    alt="icone checkup" className="image-327" />
                    <div className="font-size-6 ml-2 lh-130">Gráficos: Dividendos por ativo e Mapa do mercado</div>
                  </div>
                  <div className="item-card-plano-ouro-desktop-investap"><img
                    src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
                    alt="icone checkup" className="image-327" />
                    <div className="font-size-6 ml-2 lh-130">Calendário de dividendos (Data Com)</div>
                  </div>
                  <div className="item-card-plano-ouro-desktop-investap"><img
                    src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
                    alt="icone checkup" className="image-327" />
                    <div className="font-size-6 ml-2 lh-130">Controle manual de ações de empresas dos EUA</div>
                  </div>
                  <div className="item-card-plano-ouro-desktop-investap"><img
                    src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
                    alt="icone checkup" className="image-327" />
                    <div className="font-size-6 ml-2 lh-130">Grupo VIP com material informativo de investimentos diário</div>
                  </div>
                </div>
              </div>
              <div className="column-371 dividendosme w-col w-col-6">
                <div className="card-plano-ouro ajust-padding dividendosme">
                  <div className="item-card-plano-ouro-desktop-investap"><img
                    src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
                    alt="icone checkup" className="image-327" />
                    <div className="font-size-6 ml-2 lh-130">Controle de ações, FIIs, BDRs e Tesouro Direto</div>
                  </div>
                  <div className="item-card-plano-ouro-desktop-investap"><img
                    src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
                    alt="icone checkup" className="image-327" />
                    <div className="font-size-6 ml-2 lh-130">Acesso a cotações e indicadores atualizados</div>
                  </div>
                  <div className="item-card-plano-ouro-desktop-investap"><img
                    src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
                    alt="icone checkup" className="image-327" />
                    <div className="font-size-6 ml-2 lh-130">Sincronização automática do CEI</div>
                  </div>
                  <div className="item-card-plano-ouro-desktop-investap"><img
                    src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
                    alt="icone checkup" className="image-327" />
                    <div className="font-size-6 ml-2 lh-130">Notificação de dividendos a receber e proventos agendados</div>
                  </div>
                  <div className="item-card-plano-ouro-desktop-investap"><img
                    src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
                    alt="icone checkup" className="image-327" />
                    <div className="font-size-6 ml-2 lh-130">Controle de múltiplas carteiras e subcarteiras ilimitadas</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-cards-plano-ouro-dividendos dividendosme">
            <div className="item-card-plano-ouro-desktop-investap"><img
              src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
              alt="icone checkup" className="image-327" />
              <div className="font-size-6 ml-2 lh-130">Suporte técnico (Canal exclusivo)</div>
            </div>
            <div className="item-card-plano-ouro-desktop-investap"><img
              src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
              alt="icone checkup" className="image-327" />
              <div className="font-size-6 ml-2 lh-130">Gráficos: Dividendos por ativo e Mapa do mercado</div>
            </div>
            <div className="item-card-plano-ouro-desktop-investap"><img
              src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
              alt="icone checkup" className="image-327" />
              <div className="font-size-6 ml-2 lh-130">Calendário de dividendos (Data Com)</div>
            </div>
            <div className="item-card-plano-ouro-desktop-investap"><img
              src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
              alt="icone checkup" className="image-327" />
              <div className="font-size-6 ml-2 lh-130">Controle manual de ações de empresas dos EUA</div>
            </div>
            <div className="item-card-plano-ouro-desktop-investap"><img
              src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
              alt="icone checkup" className="image-327" />
              <div className="font-size-6 ml-2 lh-130">Grupo VIP com material informativo de investimentos diário</div>
            </div>
            <div className="item-card-plano-ouro-desktop-investap"><img
              src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
              alt="icone checkup" className="image-327" />
              <div className="font-size-6 ml-2 lh-130">Controle de ações, FIIs, BDRs e Tesouro Direto</div>
            </div>
            <div className="item-card-plano-ouro-desktop-investap"><img
              src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
              alt="icone checkup" className="image-327" />
              <div className="font-size-6 ml-2 lh-130">Acesso a cotações e indicadores atualizados</div>
            </div>
            <div className="item-card-plano-ouro-desktop-investap"><img
              src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
              alt="icone checkup" className="image-327" />
              <div className="font-size-6 ml-2 lh-130">Sincronização automática do CEI</div>
            </div>
            <div className="item-card-plano-ouro-desktop-investap"><img
              src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
              alt="icone checkup" className="image-327" />
              <div className="font-size-6 ml-2 lh-130">Notificação de dividendos a receber e proventos agendados</div>
            </div>
            <div className="item-card-plano-ouro-desktop-investap"><img
              src="./Dividendos ME_files/60abe62b8f39bedd28f01f6c_icon-check-circle.png" loading="lazy"
              alt="icone checkup" className="image-327" />
              <div className="font-size-6 ml-2 lh-130">Controle de múltiplas carteiras e subcarteiras ilimitadas</div>
            </div>
          </div>
          <div className="div-btn-dividendos-me"><a
            href="#form-cadastro"
            className="btn-primary btn-t-light-ajust-mobo w-button">Quero organizar meus investimentos</a></div>
        </div>
      </section>
      <div className="form-dark dividendos-me">
        <div className="img-form-tl-desk">
          <div className="div-block-975"><img src="./Dividendos ME_files/60e31c96f56b5558042afb39_final-pagina-img.png"
            loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1919px) 395.203125px, 474.234375px"
            srcSet="https://assets.website-files.com/5dc1d03d539fbeea86005a14/60e31c96f56b5558042afb39_final-pagina-img-p-500.png 500w, https://assets.website-files.com/5dc1d03d539fbeea86005a14/60e31c96f56b5558042afb39_final-pagina-img.png 758w"
            alt="" className="image-314" /></div>
        </div>
        <div id="form-cadastro" className="bg-form-toro-light">
          <div className="div-block-976">
            <div className="font-size-5 text-white mb-4">Cadastre-se agora no melhor App do Brasil. Venha para o futuro:</div>
            <div>
              <div className="html-embed-toro-light-cbc w-embed w-script">
                <div className="form-container">

                  <form toro-form="" id="toro.form.80b7556dfc74" registered="true" name="toro.form.80b7556dfc74" onSubmit={handleSubmit}>
                    <label htmlFor="toro.name">Nome:</label>
                    <input data-ban-invalid="" data-field="toro.name" id="toro.name.8527962b2878"
                      match="/([a-z|A-Z])+\b \b/gm" required="true" maxLength="200" ban="/\d+/gm" name="name" type="text"
                      autoComplete="off"
                      value={userName}
                      onChange={e => setUserName(e.target.value)} />
                    <div data-field-errors="toro.name"></div>

                    <label htmlFor="toro.email">E-Mail:</label>
                    <input data-field="toro.email" id="toro.email.1f11c52c341c" required="true"
                      match="/^[\w-.+]+@+[\w-.]+[.]+[\w+]+$/gm" name="email" type="email" autoComplete="off"
                      value={userEmail}
                      onChange={e => setUserEmail(e.target.value)} />
                    <div data-field-errors="toro.email"></div>
                    
                    <label htmlFor="toro.phone">Telefone:</label>
                    <PhoneInput
                      defaultCountry='BR'
                      value={phoneNumber}
                      rules={{ required: true }}
                      onChange={e =>setPhoneNumber(e)}
                    />
                    
                    <label htmlFor="toro.password">Senha (você usará para entrar no app): </label>
                    <input data-field="toro.password" id="toro.password.324d885ff2d8" required="true" minLength="6"
                      maxLength="16" name="password" type="password"
                      value={userPassword}
                      onChange={e => setUserPassword(e.target.value)} />
                    <div data-field-errors="toro.password"></div>

                    {errorMessages && errorMessages.map((item, ind) => (
                      <div className="error-message" style={{color: 'red'}} key={'errorMessage-'+ind}>
                        {item}
                      </div>
                    ))}

                    <input type="hidden" value="lp-dividendos-me" data-field="toro.campaignName"
                      id="toro.campaignName.6790913e0308" name="campaignName" />

                    <button id="submit-button" type="submit" value="ignore">
                      Cadastre-se grátis
                      <div className="loading-spinner"></div>
                    </button>

                  </form>
                </div>
                {/* <script type="text/javascript">
              let loadingSubmition = false;
              const submitButton = document.getElementById("submit-button");
              const registerForm = document.getElementById("register-form");

              submitButton.addEventListener('click', (event) => loadingSubmition && event.preventDefault());
              registerForm.addEventListener('submitError', submitButton.classNameList.remove('button-toggled'));
              registerForm.addEventListener('submit', () => {
                loadingSubmition = true;
                submitButton.classNameList.add('button-toggled');
              })
            </script> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-toro-light">
        <div className="div-block-977">
          <div className="footer-oubrain">
            <div className="container-32 w-container">
              <div className="columns-36 w-row">
                <div className="column-57 w-col w-col-3"><img src="./Dividendos ME_files/logo-nome.png"
                  width="140" alt="" className="image-84" /></div>
                <div className="w-col w-col-5">
                  <p className="paragraph-81">Pedroso Alvarenga, 1284 CJ131 <br />São Paulo-SP, Brasil</p>
                </div>
                <div className="column-87 w-col w-col-4"><a
                  href="mailto:contato@dividendos.me"
                  className="link-7">contato@dividendos.me</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalSuccessVisible && (
        <div className="bg-modal-success">
          <div className="modal-success">
            <h4>Parabéns! Sua conta no App Dividendos.me foi criada!</h4>
            <p>
              Agora baixe o App direto na loja de aplicativos do seu celular. 
              Se preferir, use os botões abaixo:
            </p>
            <div className="area-btn">
              <a href="https://apps.apple.com/br/app/dividendos-me/id1479496256" target="_blank">
                <img src={btnApple} alt="btn-apple" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.dividendos.dividendosme" target="_blank">
                <img src={btnAndroid} alt="btn-android" />
              </a>
            </div>
            <div className="btn-close" onClick={() => setModalSuccessVisible(false)}>
              Fechar
            </div>
          </div>
        </div>
      )}

    </>
  );
}