import React, {useEffect, useState, useCallback} from 'react';
import { CircularProgress } from "@material-ui/core";

import api from '../../services/api';

import Table from '../../components/common/Table';

export default function Results(){

  const [affiliateGuid, setAffiliateGuid] = useState(null);
  const [data, setData] = useState(null);

  const loadData = useCallback(async (guid) => {
    if(guid){
      try{
        const response = await api.get(`/Influencer?influencerGuid=${guid}`);

        //console.log(response);
        if(response.status === 200){
          setData(response.data.value);
          //console.log(response.data.value);
        }
      }catch(e){
        alert('Ocorreu um erro ao carregar os dados!')
      }
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if(params.id && params.id !== ''){
      //console.log(params.id);
      setAffiliateGuid(params.id);

      loadData(params.id);
    }
  }, [loadData]);

  return(
    <>
      <div className="hero-dividendos-me-section dividendosme">
        <div className="hero-plataformas-container dividendosme">
          <div className="content-hero-text-dividendos dividendosme">
            <div className="logos-plataformas-div dividendosme"><img loading="lazy"
              src="./Dividendos ME_files/60abd5167f177374df0f4778_dividendos-me-logo.png" alt="logo"
              className="logo-plataformas" /></div>
            <div className="hero-mobile-dividendosme"><img src="./Dividendos ME_files/60e31bface945b03c14ebdf5_topo-mobile.png"
              loading="lazy" alt="" className="image-333" /></div>
            <h1 className="font-size-1 text-white text-mobile-tablet-ajust-plataformas dividendos-me mt-2">Controle seus
              investimentos de um jeito <strong className="bold-text-34">simples e grátis</strong>.</h1>
            <div className="font-size-5 text-white mobo-align-center aj-mobo-f-t-light-p mt2">Utilize recursos que fazem a
              diferença na hora de administrar seus investimentos</div>
          </div>
        </div>
      </div>

      <div className='table-container'>
        <div className="container">
          {data ? (
            <Table // EnhancedTable
              tableItems={data}
              headerCells={[
                // headDividendsCells
                { id: "maskedMail", label: "E-mail" },
                { id: "createdDate", label: "Data Cadastro" },
                { id: "hasSubscription", label: "Assinante" },
                { id: "subscriptionCreatedDate", label: "Data Assinatura" },
                { id: "subscriptionType", label: "Tipo Assinatura" },
                { id: "partnerID", label: "Parceiro" },
              ]}
            />
          ) : (
            <div className="loading-container">
              <CircularProgress />
            </div>
          )}  
        </div>  
      </div>    
      
      <div className="footer-toro-light">
        <div className="div-block-977">
          <div className="footer-oubrain">
            <div className="container-32 w-container">
              <div className="columns-36 w-row">
                <div className="column-57 w-col w-col-3"><img src="./Dividendos ME_files/logo-nome.png"
                  width="140" alt="" className="image-84" /></div>
                <div className="w-col w-col-5">
                  <p className="paragraph-81">Pedroso Alvarenga, 1284 CJ131 <br />São Paulo-SP, Brasil</p>
                </div>
                <div className="column-87 w-col w-col-4"><a
                  href="mailto:contato@dividendos.me"
                  className="link-7">contato@dividendos.me</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}