import React, {useEffect, useState, useCallback} from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input'

import api from '../../services/api';

import './styles.css';

import logo from '../../assets/images/lp-hurst/logo.svg'
import gratis from '../../assets/images/lp-hurst/gratis.svg'
import telegram from '../../assets/images/lp-hurst/telegram.svg'
import whatsapp from '../../assets/images/lp-hurst/whatsapp.svg'
import instagram from '../../assets/images/lp-hurst/instagram.svg'
import downloadApple from '../../assets/images/lp-hurst/app-store.svg'
import downloadGoogle from '../../assets/images/lp-hurst/google-play.svg'
import arrowRight from '../../assets/images/lp-hurst/arrow-right.svg'


export default function HurstPremium(){

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [endPunctuation, setendPunctuation] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if(params.f && params.f !== ''){
      //console.log(params.f);
      if(params.f == 'c'){
        setendPunctuation(',');
      }else if(params.f == 'i'){
        setendPunctuation(';');
      }else if(params.f == 'r'){
        setendPunctuation(':');
      }else if(params.f == 'a'){
        setendPunctuation(`'`);
      }else{
        setendPunctuation('');
      }
    }else{
      //console.log('sem pontuação');
    }

    //console.log(endPunctuation);
  }, []);

  const handleCpfChange = (event) => {
    const { value } = event.target;

    // Remove todos os caracteres não numéricos
    const cleanedValue = value.replace(/\D/g, '');

    // Limita o valor a 11 caracteres
    const limitedValue = cleanedValue.slice(0, 11);

    // Aplica a máscara
    let maskedCpf = '';
    for (let i = 0; i < limitedValue.length; i++) {
      if (i === 3 || i === 6) {
        maskedCpf += '.';
      } else if (i === 9) {
        maskedCpf += '-';
      }
      maskedCpf += limitedValue[i];
    }

    setDocument(maskedCpf);
  };

  function validarCpf(cpf) {
    // Remove caracteres não numéricos
    cpf = cpf.replace(/\D/g, '');
  
    // Verifica se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
      return false;
    }
  
    // Verifica se todos os dígitos são iguais (ex: 111.111.111-11)
    if (/^(\d)\1{10}$/.test(cpf)) {
      return false;
    }
  
    // Calcula os dígitos verificadores
    let soma = 0;
    let resto;
  
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf[i - 1]) * (11 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
  
    if (resto !== parseInt(cpf[9])) {
      return false;
    }
  
    soma = 0;
  
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf[i - 1]) * (12 - i);
    }
  
    resto = (soma * 10) % 11;
  
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
  
    if (resto !== parseInt(cpf[10])) {
      return false;
    }
  
    return true;
  }
  

  async function handleSubmit(e){
    e.preventDefault();
    setErrorMessages([]);
    let errors = [];

    if(phone.length < 5 || !isValidPhoneNumber(phone)){
      errors.push('Insira um telefone válido');
      setErrorMessages(errors);
    }

    if(name.length < 3){
      errors.push('Insira um nome válido');
      setErrorMessages(errors);
    }

    if(email.length < 3){
      errors.push('Insira um email válido');
      setErrorMessages(errors);
    }

    if(!validarCpf(document)){
      errors.push('CPF inválido');
      setErrorMessages(errors);
    }

    if(password.length < 6){
      errors.push('Insira uma senha maior');
      setErrorMessages(errors);
    }

    if(password != confirmPassword){
      errors.push('As senhas inseridas não são iguais!');
      setErrorMessages(errors);
    }

    /* alert(
      'Nome: '+name+endPunctuation +
      '\n'+'Email: '+email+
      '\n'+'Telefone: '+phone+
      '\n'+'CPF: '+document.replace(/[.-]/g, '')+
      '\n'+'Senha: '+password+
      '\n'+'Confirma Senha: '+confirmPassword
    ); */
    
    if(errors.length === 0){
      try{
        const response = await api.post('/Hurst/lp-premium', {
          name: name+endPunctuation,
          mail: email,
          phoneNumber: phone,
          document: document.replace(/[.-]/g, ''),
          password: password
        });
  
        //console.log(response);
        if(response.status === 200){
          setName('');
          setEmail('');
          setPhone('');

          alert('Enviado com sucesso!');
        }
      }catch(e){
        //console.log(e.response);
        if(e.response.data.errorMessages){
          if(e.response.data.errorMessages.length > 0){
            setErrorMessages(e.response.data.errorMessages);
          }
        }else{
          errors.push('Ocorreu um erro!');
          setErrorMessages(errors);
        }
      }
    }else{
      setErrorMessages(errors)
    }
    
    return false;
  }

  return(
    <>
      <div className='page-hurst'>
        <div className="header">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="container">
          <div className="area-text">
            <h1>
              Seja um investidor Hurst <br /> e <b>ganhe 2 meses de <br /> assinatura Premium</b>!
            </h1>
            <p>
              Quer descobrir como investir e lucrar com royalties de música, precatórios, criptos, artes, imóveis e muito mais? E de cara ainda ganha 2 meses de assinatura Premium? 
              <br />
              Faça o cadastro gratuito e sem compromisso no maior sistema de ativos 
              <br />
              alternativos do mundo!
            </p>
            <img src={gratis} alt="gratis" className="gratis" />
          </div>
          <div className="area-form">
            <form id="form-hurst" onSubmit={handleSubmit}>
              <input 
                type="text" 
                name="name" 
                placeholder='Nome completo' 
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <input 
                type="email"
                name="email" 
                placeholder='E-mail' 
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <PhoneInput
                defaultCountry='BR'
                value={phone}
                rules={{ required: true }}
                placeholder="Telefone"
                onChange={e => setPhone(e)}
              />
              <input 
                type="text" 
                name="document" 
                placeholder='CPF' 
                value={document}
                onChange={handleCpfChange}
              />
              <input 
                type="password" 
                name="password" 
                placeholder='Senha' 
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <input 
                type="password" 
                name="confirmPassword" 
                placeholder='Confirmar senha' 
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
              {errorMessages && errorMessages.map((item, ind) => (
                <div className="error-message" style={{color: 'red'}} key={'errorMessage-'+ind}>
                  {item}
                </div>
              ))}
              <button type='submit'>
                <span>Quero criar minha conta grátis na Hurst e receber 2 meses de assinatura Premium!</span>
                <img src={arrowRight} alt="icon-submit" />
              </button>
            </form>
          </div>
        </div>
        <div className="footer">
          <div className="top-footer">
            <img src={logo} alt="logo-footer" className="logo-footer" />
          </div>
          <div className="content-footer">
            <div className="area-menu-social">
              <span className='title-menu'>Redes Sociais</span>
              <div className="menu-social">
                <a href='https://t.me/appdividendos' target='blank' className="item">
                  <img src={telegram} alt="icon-menu" />
                  <span className="text-menu">Telegram</span>
                </a>{/* 
                <a href='#' target='blank' className="item">
                  <img src={whatsapp} alt="icon-menu" />
                  <span className="text-menu">Whatsapp</span>
                </a> */}
                <a href='https://www.instagram.com/appdividendos/' target='blank' className="item">
                  <img src={instagram} alt="icon-menu" />
                  <span className="text-menu">Instagram</span>
                </a>
              </div>
            </div>
            <div className="area-menu-download">
              <span className='title-menu'>Baixe o app</span>
              <p>
                Você em qualquer lugar, seus 
                <br />
                investimentos sempre sob controle.
              </p>
              <div className="area-btn">
                <a href="https://apps.apple.com/br/app/dividendos-me/id1479496256" target='blank' className="btn">
                  <img src={downloadApple} alt="btn-download" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.dividendos.dividendosme" target='blank' className="btn">
                  <img src={downloadGoogle} alt="btn-download" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}