import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Home from './pages/Home';
import Results from './pages/Results';
import Hurst from './pages/Hurst';
import HurstPremium from './pages/HurstPremium';

export default function Routes(){
  return(
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/results' exact component={Results} />
        <Route path='/hurst' exact component={Hurst} />
        <Route path='/hurst-premium' exact component={HurstPremium} />
      </Switch>
    </BrowserRouter>
  );
}