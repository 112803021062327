import React from "react";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@material-ui/core";

import { formatDate } from "./functions";
import {
	useEnhancedTableHeadStyles,
	useNoResultsStyles,
	useStyles,
} from "./style";
// import { getTextColor } from '../../../styles/themes/themeFunctions';

function descendingComparator(a, b, orderBy) {
	if (orderBy === "value") {
		let newA = a[orderBy];
		let newB = b[orderBy];
		if (typeof newA === "string") {
			newA = newA.replace(",", "").replace(".", "");
			newA = parseFloat(newA) / 100;
		}
		if (typeof newB === "string") {
			newB = newB.replace(",", "").replace(".", "");
			newB = parseFloat(newB) / 100;
		}
		if (newB < newA) {
			return -1;
		}
		if (newB > newA) {
			return 1;
		}
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort, headerCells } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	const classesStyle = useEnhancedTableHeadStyles();

	const styleCell = (index) => {
		if (index === 0) {
			return classesStyle.curvedRightTableElement;
		} /*else if (index === headerCells.length - 1) {
			return classesStyle.curvedLeftTableElement;
		} */ else {
			return classesStyle.styledTableRow;
		}
	};

	return (
		<TableHead>
			<TableRow>
				{headerCells.map((headCell, index) => (
					<TableCell
						key={headCell.id}
						sortDirection={orderBy === headCell.id ? order : false}
						className={styleCell(index)}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
							classes={{
								root: classesStyle.baseSortElement,
								active: classesStyle.baseSortActiveElement,
							}}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

function EnhancedTable({
	headerCells,
	tableItems,
	/*dividendCalendarType,*/ onClickCallback = (event, dividend) => {},
}) {
	// const themeReducer = useSelector((state) => state.themeReducer);

	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("title");

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const classes = useStyles();
	const emptyStyle = useNoResultsStyles();

	return (
		<div className={classes.root}>
			{tableItems.length ? (
				<TableContainer className={classes.tableContainer}>
					<Table
						stickyHeader
						aria-label="sticky table, enhanced table"
						aria-labelledby="tableTitle"
						className={classes.table}
					>
						<EnhancedTableHead
							className={classes.emptySpan}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={tableItems.length}
							headerCells={headerCells}
						/>

						<TableBody>
							{stableSort(
								tableItems,
								getComparator(order, orderBy)
							).map((row, index) => {
								const labelId = `enhanced-table-checkbox-${index}`;
								const res = headerCells.map((item) => item.id);

								return (
									<TableRow
										hover
										tabIndex={-1}
										key={index}
										// onClick={(event) => onClickCallback(event, row)}
									>
										{res.map((item, index) => {
											let firstItemProps = null;
											const firstItem = index === 0;

											if (firstItem) {
												firstItemProps = {
													component: "th",
													id: labelId,
													scope: "row",
												};
											}

											return (
												<TableCell
													// component="th"
													// id={labelId}
													// scope="row"
													{...firstItemProps}
													align={
														firstItem
															? "left"
															: "center"
													}
													className={
														classes.neutralValueCell
													}
												>
													<span
														className={
															classes.emptySpan
														}
													>
														.
													</span>
													{item === "url" ? (
														<a
															href={row.url}
															target="_blank"
															rel="noopener noreferrer"
														>
															link
														</a>
													) : item === 'partnerID' ? (
														row[item] == undefined ? (
															<span> - </span>
														) : row[item] == '2' || row[item] == 2 ? (
															<span>Toro Inv.</span>
														) : row[item] == '6' || row[item] == 6 ? (
																<span>Pix Anual</span>
														) : (
															row[item]
														)
													) : row[item] == true ? (
														<spam>Sim</spam>
													) : row[item] == false ? (
														<spam>Não</spam>
													) : item === 'subscriptionCreatedDate' ? (
														row[item] == undefined ? (
															<span> - </span>
														) : (
															formatDate(row[item])
														)
													) : item === 'createdDate' ? (
														row[item] == undefined ? (
															<span> - </span>
														) : (
															formatDate(row[item])
														)
													) : item === "date" ? (
														formatDate(row[item])
													) : (
														row[item]
													)}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<div className={emptyStyle.content}>
					<p
						className={emptyStyle.text}
						style={{
							color: "#434343" /*getTextColor(themeReducer)*/,
						}}
					>
						Nenhum registro cadastrado
					</p>
				</div>
			)}
		</div>
	);
}

export default EnhancedTable;
